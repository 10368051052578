const config = {
  rootProjectId: "root",
  uiBucketName: "wizzotestme.c.retter.io",
  appUrl: "https://api.wizzotestme.retter.io/",
  cosUrl: "api.wizzotestme.retter.io",
  version: "2.1.15",
  captchaKey: "6LclsQ8qAAAAANksjN2vg1LNAIivp_po4n6UHxRJ",
  stage: "PROD"
}
export default config

